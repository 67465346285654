import React from "react"
import { Link } from "gatsby"

import ContentPage from '../components/ContentPage';
import PageImage from '../components/PageImage';

import image from '../images/undraw_page_not_found.svg';

function PageNotFound() {

    return <ContentPage>
        <h1>Oops!</h1>
        <PageImage image={image} alt='Page not found' />
        <p>We couldn't find the page that you were looking for.</p>
        <Link to='/' className='btn btn-sm'>Go to the Home page</Link>
    </ContentPage>

}

export default PageNotFound;